.facebook {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: background-color .218s,border-color .218s;
    transition: background-color .218s,border-color .218s;
    user-select: none;
    -webkit-user-select: none;
    appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border: 1px solid #dadce0;
    color: #3c4043;
    cursor: pointer;
    font-family: "Google Sans",arial,sans-serif;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    padding: 0 8px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    &:hover {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-color: #d2e3fc;
        outline: none;
        background: #fff;
    }
    &:active {
        background-color: rgba(66,133,244,.1) !important;
        border: 1px solid #dadce0 !important;
    }
}